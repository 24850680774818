.main-con {
  display: flex;
  width: 1000px;
  height: 80px;
  margin-top: 30px;
}

button {
  position: relative;
  height: 45px;
  width: 240px;
  /* margin: 10px 7px; */
  padding: 5px 5px;
  font-weight: 700 !important;
  font-size: 14px !important; 
  letter-spacing: 2px !important;
  color: #383736;
  border: 1.2px #db1a81 solid;
  border-radius: none;
  text-transform: uppercase;
  outline: 0;
  display: inline-block;
  background: none;
  z-index: 1;
  cursor: pointer;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.fill:hover {
  color: black;
  border: solid whitesmoke 2px;
  transition: 0.25s;
}

.fill:before {
  content: "";
  position: absolute;
  background: whitesmoke;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  display: block;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.fill:hover:before {
  top: 0;
}

.fill:after {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}

/* CSS BLOCK */

.btb {
  /* // margin: 50px auto; */
  letter-spacing: 1px;
  border: 4px solid white;
  color: white;
  display: inline-block;
  line-height: 100%;
  position: relative;
  text-decoration: none;
  z-index: 0;
  -webkit-transition: border-color 0.35s ease, background 0.35s ease;
  transition: border-color 0.35s ease, background 0.35s ease;
}

.btb:before {
  background-color: white;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.btb:hover:before {
  top: 0;
}

.btb:hover {
  color: #2a4556;
  transition: 0.25s;
}

.btb:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.4em;
}

.btb {
  padding: 0.9375rem 1.875rem;
}

.button__bottom-top {
  border: 2px solid black;
  background: linear-gradient(to top, #000 50%, #fff 50%);
  background-size: 100% 200%;
  background-position: top;
  transition: all 0.35s ease-out;
}

.button__bottom-top:hover {
  border: 2px solid limegreen;
  background-position: bottom;
  color: #fff;
}
